<template>
    <div>
        <a
            href="#"
            class="navi-item"
            id="kt_quick_user_toggle"
            data-toggle="tooltip"
            data-placement="right"
            data-container="body"
            data-boundary="window"
            title=""
            data-original-title="Profil utilisateur"
        >
            <div>
                <span class="btn btn-clean btn-lg">
                    <span class="svg-icon svg-icon-xxl mr-0">
                        <inline-svg src="/media/svg/icons/General/User.svg" />
                    </span>
                    <div class="text-nowrap">Mon profil</div>
                </span>
            </div>
        </a>

        <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right h-auto p-5 border">
            <!--begin::Header-->
            <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
                <h3 class="font-weight-bold m-0">
                    Mon profil
<!--                    <small class="text-muted font-size-sm ml-2">Mes infos</small>-->
                </h3>
                <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
                    <i class="ki ki-close icon-xs text-muted"></i>
                </a>
            </div>
            <!--end::Header-->

            <!--begin::Content-->
            <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
                <!--begin::Header-->
                <div class="d-flex align-items-center mt-5">
                    <div class="mr-5 w-25">
                        <img class="" :src="$store.getters.currentUser.avatar" style="border-radius: 10px;" alt="" />
                        <i class="symbol-badge bg-success " hidden></i>
                    </div>
                    <div class="d-flex flex-column w-75">
                        <p class="font-weight-bold font-size-h5 text-dark-75">
                            {{ $store.getters.currentUser.firstname }}
                            {{ $store.getters.currentUser.lastname }}
                        </p>
                        <div class="text-muted mt-1">
                            {{ $store.getters.currentUser.phone }}
                        </div>
                        <div class="navi mt-2" v-if="$store.getters.currentUser.email">
                            <a href="#" class="navi-item">
                                <span class="navi-link p-0 pb-2">
                                    <span class="navi-icon mr-1">
                                        <span class="svg-icon svg-icon-lg svg-icon-dark-75">
                                            <!--begin::Svg Icon-->
                                            <inline-svg src="/media/svg/icons/Communication/Mail.svg" />
                                            <!--end::Svg Icon-->
                                        </span>
                                    </span>
                                    <span class="navi-text text-dark-75" style="word-break: break-word;">
                                        {{ $store.getters.currentUser.email }}
                                    </span>
                                </span>
                            </a>
                        </div>
                        <div
                            class="btn btn-light-info disabled text-primary btn-bold mb-2"
                            v-if="$store.getters.currentUser.role === 'admin' || $store.getters.currentUser.role === 'gestionnaire'"
                        >
                            Rôle : {{ $store.getters.currentUser.role.charAt(0).toUpperCase() + $store.getters.currentUser.role.slice(1) }}
                        </div>
                        <div class="btn btn-light-info disabled text-primary btn-bold mb-2" v-if="$store.getters.currentUser.customer">
                            Établissement : {{ $store.getters.currentUser.customer.name.toUpperCase() }}
                        </div>
                    </div>
                </div>
                <a :href="logoutHref" class="btn btn-light-warning btn-bold btn-block mt-4">
                    Déconnexion
                </a>
                <!--end::Header-->
                <!--begin::Nav-->
                <div class="navi navi-spacer-x-0 p-0">
                    <!--begin::Item-->
                    <router-link to="/builder" @click.native="closeOffcanvas" href="#" class="navi-item" v-if="false">
                        <div class="navi-link">
                            <div class="symbol symbol-40 bg-light mr-3">
                                <div class="symbol-label">
                                    <span class="svg-icon svg-icon-md svg-icon-success">
                                        <!--begin::Svg Icon-->
                                        <inline-svg src="/media/svg/icons/General/Settings-1.svg" />
                                        <!--end::Svg Icon-->
                                    </span>
                                </div>
                            </div>
                            <div class="navi-text">
                                <div class="font-weight-bold">
                                    Mon Profil
                                </div>
                                <div class="text-muted">
                                    Modifier vos informations
                                </div>
                            </div>
                        </div>
                    </router-link>
                    <!--end:Item-->
                </div>
                <!--end::Nav-->
            </perfect-scrollbar>
            <!--end::Content-->
        </div>
    </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
    overflow: hidden;
}
</style>

<script>
import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
import KTOffcanvas from '@/assets/js/components/offcanvas.js';

export default {
    name: 'KTQuickUser',
    data() {
        return {};
    },
    mounted() {
        // Init Quick User Panel
        KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
    },
    methods: {
        closeOffcanvas() {
            new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
        }
    },
    computed: {
        picture() {
            return `${process.env.BASE_URL}media/users/default.jpg`;
        },
        logoutHref() {
            return `${process.env.VUE_APP_PLATEFORME_URL}/logout`;
        },
        plateformeHref() {
            return `${process.env.VUE_APP_PLATEFORME_URL}`;
        }
    }
};
</script>
